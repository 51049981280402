import React from "react"
import { Link } from "gatsby"
import SecHeader from "../components/secondHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import multipur from "../images/MultipurposeRoom1.png"
import kitchen from "../images/kitchen1.png"
import conferenceroom from "../images/ConferenceRoom2.png"
import rent_banner from "../images/multipurpose6.png"

const RentSpace = () => (
  <Layout>
  	<SecHeader pageTitle="Rent Space" image={rent_banner}/>
    <SEO title="Rent Space" />

    <div class="uk-container uk-section uk-section-default">

        

        <div class="uk-child-width-1-2@m uk-flex flexdirection" uk-grid>
            <div>
                <h2> Commercial Kitchen</h2>

                <p>Our licensed, Commercial Kitchen is 697 square feet, featuring a large center prep island, a wash and prep station, commercial grade oven, stove and refrigerators.
                 A garage is also conveniently located off the Kitchen, and serves as one of three access points. Our Kitchen serves as an incubator for any specialty foods manufacturer or caterer depending upon the suitability of the kitchen for their product(s). 
				</p>

            </div>
            <div class="rentSpaceMar uk-width-1-3@l "  >
            <img class="uk-width-xlarge " src={kitchen} alt="kitchen space"/>
                
            </div>
            
        </div>

    </div>
    <div class="uk-container uk-section-small uk-section-default">

        

        <div class="uk-child-width-1-2@m uk-flex flexdirection" uk-grid>
            <div>
                <h2> Multi-Purpose Area</h2>
                <p>The Multi-Purpose Room is 3,352 square and can hold 250 people. The room comes with wi-fi, in house intercom system to play music and microphone system. 
                This area is perfect for celebrations, meetings, receptions, parties, training sessions, and other events.

. 
				</p>
            </div>
            <div class="rentSpaceMar uk-width-1-3@l" >
            <img class="uk-width-xlarge" src={multipur} alt="multi purpose room"/>
                
            </div>
            
        </div>

    </div>
    <div class="uk-container uk-section-small uk-section-default">

        

        <div class="uk-child-width-1-2@m uk-flex flexdirection" uk-grid>
            <div>
                <h2> Conference Room</h2>
                <p>The Conference Room is 393 sq.ft. and also includes wi-fi and intercom system. 
                Additionally the room is equipped with a long rectangle table with conference chairs that can seat a total of ten people comfortably.
. 
				</p>
            </div>
            <div class=" rentSpaceMar uk-width-1-3@l" >
            <img class="uk-width-xlarge" src={conferenceroom} alt="conference room"/>
                
            </div>
            
        </div>

        <div class="programCon uk-padding-small uk-border-rounded uk-width-1-4@m uk-margin-top">
                <h3 style={{color: "#E5901A"}}>Contact Info:</h3>
                  <p> Willie Powell <br />
                   wpowell@cflc.org<br />

          
                </p>
                </div>
    </div>
   
  </Layout>
)

export default RentSpace